import useDispatch from './useAppDispatch';
import useSelector from './useAppSelector';
import getWPLHFirebase from '../api/journal/wplh/getWPLH';
import deleteWPLHFirebase from '../api/journal/wplh/deleteWPLH';
import {
  selectWPLH,
  selectWPLHIsUpdating,
  setWPLH,
  wplhCreateNewItem,
  wplhLoadExistingItem,
  wplhSubmit,
  wplhUpdateLevels,
  wplhUpdateReflection,
} from '../redux/slices/wplhSlice';
import { WPLHDashboard } from '../types/journalItems/WPLHDashboard';
import useUser from './useUser';
import deleteTimelineEntries from '../api/timeline/deleteTimelineEntries';

export default function useWPLH() {
  const dispatch = useDispatch();
  const wplhDashboard = useSelector(selectWPLH);
  const isUpdating = useSelector(selectWPLHIsUpdating);

  const { user } = useUser();

  const getWPLH = async (id: string) => {
    const newWPLH = await getWPLHFirebase(id);
    if (newWPLH == null) {
      return;
    }
    dispatch(setWPLH({ wplh: newWPLH }));
    return newWPLH;
  };

  const createWPLH = async (id: string) => {
    dispatch(wplhCreateNewItem({ activityId: id }));
  };

  const loadWPLH = async (id: string) => {
    if (user == null) {
      return;
    }
    dispatch(wplhLoadExistingItem({ activityId: id }));
  };

  const updateWPLH = async (WPLHUpdated: Omit<WPLHDashboard, 'user'>) => {
    dispatch(
      setWPLH({
        wplh: { ...WPLHUpdated, user: user?.uid ?? '' },
      }),
    );
  };

  const updateWPLHLevels = (args: {
    workLevel: number;
    playLevel: number;
    loveLevel: number;
    healthLevel: number;
  }) => {
    dispatch(wplhUpdateLevels(args));
  };

  const updateWPLHReflection = (reflection: string) => {
    dispatch(wplhUpdateReflection({ reflection }));
  };

  const submitWPLH = () => {
    if (user == null) {
      return;
    }
    dispatch(wplhSubmit());
  };

  const resetWPLH = () => {
    dispatch(setWPLH({ wplh: null }));
  };

  const deleteWPLH = async (resourceId: string) => {
    if (user == null || resourceId == null) {
      return;
    }
    deleteWPLHFirebase(resourceId);
    deleteTimelineEntries({ userId: user?.uid, resourceId });
  };

  return {
    getWPLH,
    createWPLH,
    loadWPLH,
    updateWPLH,
    deleteWPLH,
    updateWPLHLevels,
    updateWPLHReflection,
    submitWPLH,
    resetWPLH,
    wplhDashboard,
    isUpdating,
  };
}
