import useDispatch from './useAppDispatch';
import useSelector from './useAppSelector';

import deletePieChartFirebase from '../api/journal/pieChart/deletePieChart';
import getPieChartFirebase from '../api/journal/pieChart/getPieChart';

import {
  pieChartCreateNewItem,
  pieChartLoadExistingItem,
  pieChartSubmit,
  pieChartUpdateCurrentActivities,
  pieChartUpdateIdealActivities,
  pieChartUpdateReflection,
  selectPieChart,
  selectPieChartIsUpdating,
  setPieChart,
} from '../redux/slices/pieChartSlice';
import { PieChart, PieChartActivity } from '../types/journalItems/PieChart';
import useUser from './useUser';
import deleteTimelineEntries from '../api/timeline/deleteTimelineEntries';

export default function usePieChart() {
  const dispatch = useDispatch();
  const pieChart = useSelector(selectPieChart);
  const isUpdating = useSelector(selectPieChartIsUpdating);

  const { user } = useUser();

  const getPieChart = async (id: string) => {
    const newPieChart = await getPieChartFirebase(id);
    if (newPieChart == null) {
      return;
    }

    dispatch(setPieChart({ pieChart: newPieChart }));
    return newPieChart;
  };

  const createPieChart = async (id: string) => {
    dispatch(pieChartCreateNewItem({ activityId: id }));
  };

  const loadPieChart = async (id: string) => {
    if (user == null) {
      return;
    }

    dispatch(pieChartLoadExistingItem({ activityId: id }));
  };

  const updatePieChart = async (pieChartUpdated: Omit<PieChart, 'user'>) => {
    if (user == null) {
      return;
    }
    dispatch(
      setPieChart({
        pieChart: { ...pieChartUpdated, user: user.uid },
      }),
    );
  };

  const updatePieChartCurrentActivities = (
    activities: Array<PieChartActivity>,
  ) => {
    dispatch(
      pieChartUpdateCurrentActivities({
        activities,
      }),
    );
  };

  const updatePieChartIdealActivities = (
    activities: Array<PieChartActivity>,
  ) => {
    dispatch(
      pieChartUpdateIdealActivities({
        activities,
      }),
    );
  };

  const updatePieChartReflection = (reflection: string) => {
    dispatch(pieChartUpdateReflection({ reflection }));
  };

  const submitPieChart = () => {
    if (user == null) {
      return;
    }
    dispatch(pieChartSubmit());
  };

  const resetPieChart = () => {
    dispatch(setPieChart({ pieChart: null }));
  };

  const deletePieChart = async (resourceId: string) => {
    if (user == null || resourceId == null) {
      return;
    }
    deletePieChartFirebase(resourceId);
    deleteTimelineEntries({ userId: user?.uid, resourceId });
  };

  return {
    getPieChart,
    createPieChart,
    loadPieChart,
    updatePieChart,
    updatePieChartCurrentActivities,
    updatePieChartIdealActivities,
    updatePieChartReflection,
    submitPieChart,
    deletePieChart,
    resetPieChart,
    pieChart,
    isUpdating,
  };
}
