import useDispatch from './useAppDispatch';
import useSelector from './useAppSelector';

import deleteSCurveFirebase from '../api/journal/sCurve/deleteSCurve';
import getSCurveFirebase from '../api/journal/sCurve/getSCurve';

import {
  sCurveCreateNewItem,
  sCurveLoadExistingItem,
  sCurveUpdateGoal,
  sCurveUpdateStage,
  sCurveUpdateReflection,
  sCurveSubmit,
  selectSCurve,
  selectSCurveIsUpdating,
  setSCurve,
} from '../redux/slices/sCurveSlice';
import { SCurve } from '../types/journalItems/SCurve';
import useUser from './useUser';
import deleteTimelineEntries from '../api/timeline/deleteTimelineEntries';

export default function useSCurve() {
  const dispatch = useDispatch();
  const sCurve = useSelector(selectSCurve);
  const isUpdating = useSelector(selectSCurveIsUpdating);

  const { user } = useUser();

  const getSCurve = async (id: string) => {
    const newSCurve = await getSCurveFirebase(id);
    if (newSCurve == null) {
      return;
    }

    dispatch(setSCurve({ sCurve: newSCurve }));
    return newSCurve;
  };

  const createSCurve = async (id: string) => {
    dispatch(sCurveCreateNewItem({ activityId: id }));
  };

  const loadSCurve = async (id: string) => {
    if (user == null) {
      return;
    }

    dispatch(sCurveLoadExistingItem({ activityId: id }));
  };

  const updateSCurve = async (sCurveUpdated: Omit<SCurve, 'user'>) => {
    if (user == null) {
      return;
    }
    dispatch(
      setSCurve({
        sCurve: { ...sCurveUpdated, user: user.uid },
      }),
    );
  };

  const updateSCurveGoal = (goal: string) => {
    dispatch(sCurveUpdateGoal({ goal }));
  };

  const updateSCurveStage = (stage: number) => {
    dispatch(sCurveUpdateStage({ stage }));
  };

  const updateSCurveReflection = (reflection: string) => {
    dispatch(sCurveUpdateReflection({ reflection }));
  };

  const submitSCurve = () => {
    if (user == null) {
      return;
    }
    dispatch(sCurveSubmit());
  };

  const deleteSCurve = async (resourceId: string) => {
    if (user == null || resourceId == null) {
      return;
    }
    deleteSCurveFirebase(resourceId);
    deleteTimelineEntries({ userId: user?.uid, resourceId });
  };

  return {
    getSCurve,
    createSCurve,
    loadSCurve,
    updateSCurve,
    updateSCurveGoal,
    updateSCurveStage,
    updateSCurveReflection,
    submitSCurve,
    deleteSCurve,
    sCurve,
    isUpdating,
  };
}
